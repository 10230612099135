export class ErrorMessage extends HTMLElement {
  static get observedAttributes() {
    return ['message']
  }

  constructor(message?: string) {
    super();
    if (message) {
      this.setAttribute('message', message);
    }
  }

  connectedCallback() {
    this.reader();
  }

  attributeChangedCallback(name: string, _oldValue: string, _newValue: string) {
    if (name === 'message' && this.isConnected) {
      this.reader();
    }
  }

  private reader() {
    const message = this.getAttribute('message') || '';

    this.innerHTML = `
    <div class='min-h-6 flex items-center my-1'>
      <svg width="24" height="24" viewBox="0 0 24 24" class="w-6 h-6">
        <path
          d="M12 22.0004C6.477 22.0004 2 17.5234 2 12.0004C2 6.47737 6.477 2.00037 12 2.00037C17.523 2.00037 22 6.47737 22 12.0004C22 17.5234 17.523 22.0004 12 22.0004ZM11 15.0004V17.0004H13V15.0004H11ZM11 7.00037V13.0004H13V7.00037H11Z"
          fill="#EC4C4C"
        ></path>
      </svg>
      <div class="pl-2 text-error text-sm font-bold">${message}</div>
    </div>
    `;
  }
}
